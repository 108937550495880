@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css");

html {
    background-color: white;
    height: 100vh;
}

//noinspection ALL
body {
    background-color: white;

    //min-width: 320px;
    //min-height: 100%;
    //max-width: 480px;
    margin: 0 auto !important;
    padding: 0 !important;
    // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    position: relative;
    background: white;
    // box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);

    --status-bar-height: constant(safe-area-inset-top);
    --status-bar-height: env(safe-area-inset-top);
    --bottom-bar-height: constant(safe-area-inset-bottom);
    --bottom-bar-height: env(safe-area-inset-bottom);
}

.transition-group {
    position: relative;
}

.pageSlider-enter {
    opacity: 0;
    // transform: scale(1.1);
}

.pageSlider-enter-active {
    opacity: 1;
    // transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.pageSlider-exit {
    opacity: 1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    // transform: scale(1);
}

.pageSlider-exit-active {
    opacity: 0;
    // transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

#root {
    width: 100%;
    //max-width: 480px;
    height: 100%;
}

* {
    box-sizing: border-box;
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
        "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
    -webkit-tap-highlight-color: transparent;
    color: white;
    letter-spacing: -0.02em;
    line-height: normal;
    user-select: none;
    -webkit-user-drag: none;
    scrollbar-width: none;
    -webkit-touch-callout: none;
}

*::-webkit-scrollbar {
    display: none;
}

pre,
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    text-decoration: none;

    &:link,
    &:hover,
    &:active,
    &:visited {
        color: inherit;
    }
}

.absolute {
    position: absolute;
    top: 0;
}

.container-transition-enter {
    transform: translateX(100%);
    transition: all 300ms ease-in-out;
    min-height: 100vh;

    &.pop {
        transform: none !important;
    }
}

.container-transition-enter.container-transition-enter-active {
    transform: translateX(0);
}

.container-transition-enter-done {
}

.container-transition-exit {
    opacity: 0;
}

.container-transition-exit.container-transition-exit-active {
}

.container-transition-exit-done {
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

input {
    appearance: none;
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
}

h1,
h2,
h3,
h4,
h5,
section {
    margin: 0;
    padding: 0;
    border: 0;
    font: 100% inherit;
    vertical-align: baseline;
}
.tooltip {
    width: fit-content;
    min-width: 50px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    right: 3px;
    top: -10px;
    z-index: 1;
    background: linear-gradient(180deg, #f097ff 0%, #e75aff 100%);
    border-radius: 8px;
    padding: 4px 8px;
}

.google-visualization-tooltip {
    background-color: transparent !important;
    border: none !important;
    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        display: block;
        right: 19px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 13px solid #e75aff;
        margin: 0px 0 0 0px;
        bottom: -16px;
    }
}

a[href^="http://maps.google.com/maps"]
{
    display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
    display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc,
.gm-style-moc {
    display: none;
}
.gmnoprint div {
    background: none !important;
}

// lazy load image fit content
.lazy-load-image-background {
    width: 100%;
    height: 100%;
}

#google-running-map [tabindex="0"] img {
    transform: scale(1.002);
}

.page-transition-enter {
    opacity: 0;
}
.page-transition-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.page-transition-exit {
    opacity: 1;
}
.page-transition-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

button:focus { outline:0 !important; }

.loading-modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
  
    .loading-indicator{
      $size: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin: 0 auto;
      width: 50%;
  
      @keyframes rotate {
        0% {
          transform: rotateX(-37.5deg) rotateY(45deg);
        }
        50% {
          transform: rotateX(-37.5deg) rotateY(405deg);
        }
        100% {
          transform: rotateX(-37.5deg) rotateY(405deg);
        }
      }
  
      .cube, .cube * {
        position: absolute;
        width: $size + 1;
        height: $size + 1;
      }
  
      .sides {
        animation: rotate 3s ease infinite;
        animation-delay: .8s;
        transform-style: preserve-3d;
        transform: rotateX(-37.5deg) rotateY(45deg);
      }
  
      .cube .sides * {
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, .5);
        background-size: cover;
        border: 2px solid rgba(255,255,255,0.5);
        border-radius: 7px;
      }
  
      @mixin side($name, $rotate, $i) {
        .#{$name} {
          animation: #{$name}-animation 3s ease infinite;
          animation-delay: $i * 100ms;
          transform: #{$rotate} translateZ($size);
          animation-fill-mode: forwards;
          transform-origin: 50% 50%;
        }
  
        
        @keyframes #{$name}-animation {
          0% { opacity: 1; transform: #{$rotate} translateZ($size)}  
          20% { opacity: 1; transform: #{$rotate} translateZ($size / 2)}  
          70% {  opacity: 1; transform: #{$rotate} translateZ($size / 2) }
          90% {  opacity: 1; transform: #{$rotate} translateZ($size) }
          100% {  opacity: 1; transform: #{$rotate} translateZ($size) }
        }
      }
  
      .cube .sides {
        @include side("top", rotateX(90deg), 0);
        @include side("bottom", rotateX(-90deg), 0);
        @include side("front", rotateY(0deg), 1);
        @include side("back", rotateY(-180deg), 1);
        @include side("left", rotateY(-90deg), 1);
        @include side("right", rotateY(90deg), 1);
      }
    }
  }